var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
} : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj;
};

function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
        for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
            arr2[i] = arr[i];
        }
        return arr2;
    } else {
        return Array.from(arr);
    }
}

/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = function () {
    "use strict";

    function t(t, e) {
        return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + t + "' height='" + e + "'%3E%3C/svg%3E"
    }

    function e(t) {
        if (t.srcset && !p && window.picturefill) {
            var e = window.picturefill._;
            t[e.ns] && t[e.ns].evaled || e.fillImg(t, {reselect: !0}), t[e.ns].curSrc || (t[e.ns].supported = !1, e.fillImg(t, {reselect: !0})), t.currentSrc = t[e.ns].curSrc || t.src
        }
    }

    function i(t) {
        for (var e, i = getComputedStyle(t).fontFamily, r = {}; null !== (e = u.exec(i));) r[e[1]] = e[2];
        return r
    }

    function r(e, i, r) {
        var n = t(i || 1, r || 0);
        b.call(e, "src") !== n && h.call(e, "src", n)
    }

    function n(t, e) {
        t.naturalWidth ? e(t) : setTimeout(n, 100, t, e)
    }

    function c(t) {
        var c = i(t), o = t[l];
        if (c["object-fit"] = c["object-fit"] || "fill", !o.img) {
            if ("fill" === c["object-fit"]) return;
            if (!o.skipTest && f && !c["object-position"]) return
        }
        if (!o.img) {
            o.img = new Image(t.width, t.height), o.img.srcset = b.call(t, "data-ofi-srcset") || t.srcset, o.img.src = b.call(t, "data-ofi-src") || t.src, h.call(t, "data-ofi-src", t.src), t.srcset && h.call(t, "data-ofi-srcset", t.srcset), r(t, t.naturalWidth || t.width, t.naturalHeight || t.height), t.srcset && (t.srcset = "");
            try {
                s(t)
            } catch (t) {
                window.console && console.warn("https://bit.ly/ofi-old-browser")
            }
        }
        e(o.img), t.style.backgroundImage = 'url("' + (o.img.currentSrc || o.img.src).replace(/"/g, '\\"') + '")', t.style.backgroundPosition = c["object-position"] || "center", t.style.backgroundRepeat = "no-repeat", t.style.backgroundOrigin = "content-box", /scale-down/.test(c["object-fit"]) ? n(o.img, function () {
            o.img.naturalWidth > t.width || o.img.naturalHeight > t.height ? t.style.backgroundSize = "contain" : t.style.backgroundSize = "auto"
        }) : t.style.backgroundSize = c["object-fit"].replace("none", "auto").replace("fill", "100% 100%"), n(o.img, function (e) {
            r(t, e.naturalWidth, e.naturalHeight)
        })
    }

    function s(t) {
        var e = {
            get: function (e) {
                return t[l].img[e ? e : "src"]
            }, set: function (e, i) {
                return t[l].img[i ? i : "src"] = e, h.call(t, "data-ofi-" + i, e), c(t), e
            }
        };
        Object.defineProperty(t, "src", e), Object.defineProperty(t, "currentSrc", {
            get: function () {
                return e.get("currentSrc")
            }
        }), Object.defineProperty(t, "srcset", {
            get: function () {
                return e.get("srcset")
            }, set: function (t) {
                return e.set(t, "srcset")
            }
        })
    }

    function o() {
        function t(t, e) {
            return t[l] && t[l].img && ("src" === e || "srcset" === e) ? t[l].img : t
        }

        d || (HTMLImageElement.prototype.getAttribute = function (e) {
            return b.call(t(this, e), e)
        }, HTMLImageElement.prototype.setAttribute = function (e, i) {
            return h.call(t(this, e), e, String(i))
        })
    }

    function a(t, e) {
        var i = !y && !t;
        if (e = e || {}, t = t || "img", d && !e.skipTest || !m) return !1;
        "img" === t ? t = document.getElementsByTagName("img") : "string" == typeof t ? t = document.querySelectorAll(t) : "length" in t || (t = [t]);
        for (var r = 0; r < t.length; r++) t[r][l] = t[r][l] || {skipTest: e.skipTest}, c(t[r]);
        i && (document.body.addEventListener("load", function (t) {
            "IMG" === t.target.tagName && a(t.target, {skipTest: e.skipTest})
        }, !0), y = !0, t = "img"), e.watchMQ && window.addEventListener("resize", a.bind(null, t, {skipTest: e.skipTest}))
    }

    var l = "bfred-it:object-fit-images", u = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g,
        g = "undefined" == typeof Image ? {style: {"object-position": 1}} : new Image, f = "object-fit" in g.style,
        d = "object-position" in g.style, m = "background-size" in g.style, p = "string" == typeof g.currentSrc,
        b = g.getAttribute, h = g.setAttribute, y = !1;
    return a.supportsObjectFit = f, a.supportsObjectPosition = d, o(), a
}();

define([
        'clipboard',
        'bodyScrollLock',
        'jquery',
        'slick',
        'menuAim',
        'Magento_Ui/js/modal/modal'
    ],
    function (ClipboardJS, bodyScrollLock, $) {
        'use strict'

        var bp = {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1024,
            xl: 1200,
            xxl: 1440
        };

        var dateNow = new Date();
        var deadlinePA = new Date(dateNow.setHours(23, 59, 59, 999)).toISOString();
        //var deadlineEaster = '2020-04-05T22:59:59Z';
        var deadlineBF8 = new Date(dateNow.setHours(23, 59, 59, 999)).toISOString();
        //var deadlineBF100 = '2021-11-07T22:59:59Z';
        //var deadlineSanta = '2020-12-20T22:59:59Z';
        //var deadlineVacation = '2021-07-11T21:59:59Z';
        //var deadlineDeco = '2022-01-16T22:59:59Z';

        /**
         * Ready Event
         */

        $(document).ready(function () {
            bestsellerPosition();
            homepageSlider();
            productsSlider(); //all products list slider
            headerBelt();
            showMenu();
            homepageReviews();
            homepagePopularCategories();
            footerContentToggle();
            sidebarMenuContentToggle();
            copyToClipboard();
            backToTop();
            handleStickyHeader();
            openTabCustomLinks();
            galleryStore();
            headerBeltExtra();
            //chatDeco();
            faqToogle();
            faqFilterQuestion();
            faqSearch();
            showMore();
            msieversion();
            landingProductDetailsSLider();
            modalVideo();
            contactMap();
            modalWalk();
            rankingToogle();
            adventCalendar();
            landingColors();
            landingColorsSlider();
            landingAccessoriesSlider();
            lpSwitcher();
            lpProductAdvantages();
            lpCategories();

            //giftCardFreeDeliveryInfo
            if (document.querySelector('.cart-free-delivery:not(.oloo-covid)')) {
                if (document.querySelector('.cart.item').classList.contains('item-type-bss_giftcard')) {
                    document.querySelector('.cart-free-delivery:not(.oloo-covid)').style.display = 'none';
                }
            }

            if (document.querySelector('.contact-index-index')) {
                $('a[href^="#"]').click(function (e) {
                    let headerNav = document.querySelector('.page-header .header.content');
                    let headerNavHeight = headerNav ? headerNav.offsetHeight : 64;
                    e.preventDefault();

                    $('html, body').animate({
                        scrollTop: $($.attr(this, 'href')).offset().top - headerNavHeight
                    }, 'slow');
                });
            }

            if (document.querySelector('.faq-decofire') || document.querySelector('.contact-locations')) {
                let headerNav = document.querySelector('.page-header .header.content');
                let headerNavHeight = headerNav ? headerNav.offsetHeight : 64;
                let hash = window.location.hash;

                if (hash) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $(hash).offset().top - headerNavHeight
                        }, 'slow');

                        if (hash.includes('#kategoria')) {
                            $('.faq-category .faq-category-item' + hash).trigger('click');
                        } else {
                            $(hash).addClass('toggle');
                        }
                    }, 1000);
                }
            }

            if (document.querySelector('.expert')) {
                var expertBelt = function expertBelt() {
                    var productListOffsetTop = document.querySelector('.product-items').offsetTop;
                    var productListHeight = parseInt(document.querySelector('.product-items').offsetHeight, 10);
                    var halfProductHeight = parseInt(document.querySelector('.product-items .product-item').offsetHeight / 2, 10);
                    var showExpert = parseInt(productListOffsetTop + halfProductHeight, 10);
                    var element = document.querySelector('.expert');

                    if (scrollY > showExpert) {
                        element.classList.add('show');
                        document.body.classList.add('expert-show');
                    }

                    if (scrollY < showExpert && element.classList.contains('show')) {
                        element.classList.remove('show');
                        document.body.classList.remove('expert-show');
                    }

                    if (scrollY > productListHeight && element.classList.contains('show')) {
                        element.classList.add('relative');
                    } else {
                        element.classList.remove('relative');
                    }

                    element.querySelector('.expert-header').addEventListener('click', function (e) {
                        e.stopPropagation();
                        e.stopImmediatePropagation();

                        element.classList.toggle('collapse');
                    });
                };

                window.addEventListener('scroll', expertBelt);
            }

            if (document.querySelector('.product-mini-gallery')) {
                var images = [].concat(_toConsumableArray(document.querySelectorAll('.product-mini-gallery img')));

                images.forEach(function (item) {
                    var itemAlt = item.getAttribute('alt');

                    if (itemAlt) {
                        (function () {
                            var itemTooltip = '<span class="image-tooltip">' + itemAlt + '</span>';

                            item.addEventListener('mouseover', function () {
                                item.insertAdjacentHTML('afterend', itemTooltip);
                            });

                            item.addEventListener('mouseout', function () {
                                document.querySelector('.image-tooltip').remove();
                            });
                        })();
                    }
                });

                $('.product-mini-gallery').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [{
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }, {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }, {
                        breakpoint: 544,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
            }

            if (document.querySelector('.deco-bf8-banner')) {
                initializeClock('bf8-b-counter', deadlineBF8);
            }

            if (document.querySelector('.deco-bf100-banner')) {
                initializeClock('bf100-b-counter', deadlineBF100);
            }

            if (document.querySelector('.blackfriday-decofire.bf8')) {
                initializeClock('bf8-b-counter', deadlineBF8);
            }

            if (document.querySelector('.blackfriday-decofire.bf100')) {
                initializeClock('bf100-b-counter', deadlineBF100);
            }

            if (document.querySelector('.deco-christmas-banner')) {
                initializeClock('santa-b-counter', deadlineSanta);
            }
            //
            if (document.querySelector('.christmas-decofire')) {
                initializeClock('santa-b-counter', deadlineSanta);
            }
            if (document.querySelector('.prima-aprillis-decofire')) {
                initializeClock('prima-b-counter', deadlineDeco);
            }

            if (document.querySelector('.header-belt-prima-aprillis')) {
                initializeClock('santa-counter', deadlineDeco);
            }

            if (document.querySelector('.easter-decofire')) {
                initializeClock('easter-b-counter', deadlineEaster);
            }

            if (document.querySelector('.header-belt-easter')) {
                initializeClock('easter-counter', deadlineEaster);
            }

            if (document.querySelector('.vacation-decofire')) {
                initializeClock('vacation-b-counter', deadlineVacation);
            }

            if (document.querySelector('.pp-today')) {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1;
                var yyyy = today.getFullYear();

                if (dd < 10) dd = '0' + dd;

                if (mm < 10) mm = '0' + mm;

                today = dd + '.' + mm + '.' + yyyy;

                var currentDate = document.querySelector('.pp-today');
                currentDate.innerHTML += today;
            }

            /*
             * Object fit images
             */
            $(function () {
                objectFitImages();
            });
        });

        //modal -- support scroll for iOS and other ;)
        $(document).on('modalopened', function () {
            bodyScrollLock.disableBodyScroll(document.querySelector('.modal-popup._show'));
        });

        $(document).on('modalclosed', function () {
            bodyScrollLock.clearAllBodyScrollLocks();
        });

        $(document).on('mana-after-content-replaced', function (event, $containers) {
            bestsellerPosition();
        });

        function handleStickyHeader() {
            var header = document.querySelector('.page-header');
            var headerNav = document.querySelector('.page-header .header.content');
            var headerTop = header ? header.offsetHeight : 184;
            var headerNavHeight = headerNav ? headerNav.offsetHeight : 96;
            var backTop = document.querySelector('.back-top');

            function stickHeader() {
                if (header !== null) {
                    if (window.scrollY >= headerTop) {
                        header.style.paddingTop = headerNavHeight + 'px';
                        document.body.classList.add('fixed-header');
                    } else {
                        header.style.paddingTop = 0;
                        document.body.classList.remove('fixed-header');
                    }
                }

                if (window.matchMedia('(min-width: ' + bp.md + 'px)').matches) {
                    if (backTop) {
                        if (window.scrollY > 300) {
                            backTop.classList.add('show');
                        } else {
                            backTop.classList.remove('show');
                        }
                    }
                }
            }

            window.addEventListener('scroll', stickHeader);
        }

        function homepageSlider() {
            $('.slider-homepage').animate({}, function () {
                $('.slider-hp').slick({
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    responsive: [{
                        breakpoint: 575,
                        settings: {
                            arrows: false
                        }
                    }]
                });
            });
        }

        function homepageReviews() {
            $('.deco-reviews-section').animate({}, function () {
                var module = $(this),
                    carousel = module.find('.product-items');

                var destroySlider = function destroySlider() {
                    if (carousel.hasClass('slick-initialized')) {
                        carousel.slick('unslick');
                    }
                };

                $(window).on('load resize orientationchange', function () {
                    if (!window.matchMedia('(min-width: ' + bp.md + 'px)').matches) {
                        if (!carousel.hasClass('slick-initialized')) {
                            carousel.slick({
                                arrows: true,
                                dots: false,
                                infinite: false,
                                mobileFirst: true
                            });
                        }
                    } else {
                        destroySlider();
                    }
                });
            });
        }

        function homepagePopularCategories() {
            $('.deco-popular-categories-section').animate({}, function () {
                var module = $(this),
                    carousel = module.find('.deco-popular-categories');

                var destroySlider = function destroySlider() {
                    if (carousel.hasClass('slick-initialized')) {
                        carousel.slick('unslick');
                    }
                };

                $(window).on('load resize orientationchange', function () {
                    if (!window.matchMedia('(min-width: ' + bp.md + 'px)').matches) {
                        if (!carousel.hasClass('slick-initialized')) {
                            carousel.slick({
                                arrows: true,
                                dots: false,
                                infinite: false,
                                mobileFirst: true
                            });
                        }
                    } else {
                        destroySlider();
                    }
                });
            });
        }

        function footerContentToggle() {
            $('.page-footer .footer').on('click touch', '.footer-box .title', function () {
                $(this).toggleClass('active');
                $(this).next('.links').toggleClass('active');
            });
        }

        function sidebarMenuContentToggle() {
            $('.sidebar .block-cms-menu-left').on('click touch', '.block-title', function () {
                $(this).toggleClass('active');
                $(this).next('.block-content').toggleClass('active');
            });
        }

        function productsSlider() {
            $('.products-slider:not(.no-slider)').animate({}, function () {
                var sliderCount = void 0;
                if (window.matchMedia('(min-width: ' + bp.xs + 'px)').matches) {
                    sliderCount = 2
                }
                if (window.matchMedia('(min-width: ' + bp.md + 'px)').matches) {
                    sliderCount = 3
                }
                if (window.matchMedia('(min-width: ' + bp.lg + 'px)').matches) {
                    sliderCount = 4
                }

                $('.products-slider:not(.no-slider) .product-items').each(function () {
                    var sliderAuto = $(this).parent().hasClass('slider-auto');
                    var slideToScroll = sliderAuto ? 1 : 4;

                    if ($(this).children('li.product-item').length > sliderCount) {
                        $(this).not('.slick-initialized').slick({
                            arrows: true,
                            dots: false,
                            infinite: sliderAuto,
                            speed: 300,
                            slidesToShow: 4,
                            slidesToScroll: slideToScroll,
                            autoplay: sliderAuto,
                            autoplaySpeed: 1500,
                            responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                },
                                {
                                    breakpoint: 544,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                    }
                });
            });
        }

        function copyToClipboard() {
            if (document.querySelector('.deco-icon-url')) {
                var clipboard = new ClipboardJS('.deco-icon-url');

                clipboard.on('success', function (e) {
                    e.trigger.classList.add('success');

                    setTimeout(function () {
                        return e.trigger.classList.remove('success');
                    }, 1200);
                })
            }
        }

        function backToTop() {
            $('.back-top').animate({}, function () {
                $('.back-top').on('click', function () {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 500);
                    return false;
                })
            })
        }

        function bestsellerPosition() {
            $('body.page-products:not(.bestsellery-index-index) #mana_ajax_wrapper_category_products_list').animate({}, function () {
                var t = '#mana_ajax_wrapper_category_products_list ';
                var bestsellerItemsCount = $(t + '.product-item-size2').length;
                //let positionB = [1, 7, 11, 17, 21, 27, 31]
                var positionB = 1;
                for (var i = 0; i < bestsellerItemsCount; i++) {
                    $(t + '.product-item-size2:eq(' + i + ')').insertBefore($(t + '.product-item:nth-child(' + positionB + ')'));
                    positionB = i % 2 ? positionB += 4 : positionB += 6;
                }
            });
        }

        function showMenu() {
            $('.header.content .menu').animate({}, function () {
                if (window.matchMedia('(min-width: ' + bp.lg + 'px)').matches) {
                    (function () {
                        var setTimeoutConst = void 0;

                        $('.header.content .menu > ul > li:nth-of-type(1)').hover(function (e) {
                            e.preventDefault();

                            setTimeoutConst = setTimeout(function () {
                                $(this).addClass('show-menu');
                                $('body').addClass('show-menu');

                                // //show first submenu
                                if (!$('.menu-deco > ul > li.level0').hasClass('active')) {
                                    $('.menu-deco > ul > li.level0.nav-1').addClass('active');
                                    $('.menu-deco > ul > li.level0.nav-1 .level0.submenu').addClass('submenu-visible');
                                }

                                // if (e.currentTarget !== this) {
                                //   $('.menu-deco > ul > li.level0').removeClass('active');
                                //   $('.menu-deco > ul > li.level0 .submenu').removeClass('submenu-visible');
                                //
                                //   $(e.target).parent().addClass('active');
                                //   $(e.target).parent().children('.level0.submenu').addClass('submenu-visible');
                                // }
                            }, 100);
                        }, function () {
                            clearTimeout(setTimeoutConst);
                            $('.menu-deco > ul > li.level0').removeClass('active');
                            $('.menu-deco > ul > li.level0 .submenu').removeClass('submenu-visible');

                            $('.header.content .menu li').removeClass('show-menu');
                            $('body').removeClass('show-menu');
                        });

                        var $menu = $('.header.content nav.menu ul');

                        $menu.menuAim({
                            tolerance: 90,
                            activate: function activate(a) {
                                $(a).children('.submenu').addClass('submenu-visible');
                                $(a).addClass('active');
                            },
                            deactivate: function deactivate(a) {
                                $(a).children('.submenu').removeClass('submenu-visible');
                                $(a).removeClass('active');
                            }
                        });

                        //Home Page
                        if ($('.nav-homepage').length) {
                            (function () {
                                var setTimeoutConst = void 0;

                                $('.nav-homepage .navigation ul li a').hover(function (e) {
                                    e.preventDefault();
                                    var liIndex = $(this).parent().index() + 1;

                                    setTimeoutConst = setTimeout(function () {
                                        $('.header.content .menu > ul > li:nth-of-type(1)').addClass('show-menu');
                                        $('body').addClass('show-menu');

                                        if (!$('body').hasClass('fixed-header')) {
                                            $('.menu-deco > ul > li.level0.nav-' + liIndex).addClass('active');
                                            $('.menu-deco > ul > li.level0.nav-' + liIndex + ' .level0.submenu').addClass('submenu-visible');
                                        }
                                    }, 250);
                                }, function () {
                                    clearTimeout(setTimeoutConst);
                                });
                            })();
                        }
                    })();
                } else {
                    (function () {
                        var navExpand = [].concat(_toConsumableArray(document.querySelectorAll('.menu-deco .parent')));
                        var backLink = '<li class="category-item"><a class="nav-back-link" href="javascript:;"></a></li>';
                        var menuTitle = '<li class="category-item"><a class="nav-back-link nav-close-link" href="javascript:;">\n                            <span>Menu</span><span class="nav-toggle"><span></span></span></a></li>';
                        var menuFirstItem = document.querySelector('.level0.nav-1');

                        navExpand.forEach(function (item) {
                            var itemParentHref = item.querySelector('.submenu').parentNode.firstChild.getAttribute('href');
                            var itemParentText = item.querySelector('.submenu').parentNode.firstChild.textContent;
                            var itemParent = '<li class="category-item"><a href="' + itemParentHref + '"><span>Zobacz wszystkie</span></li>';
                            var itemBackTextExtra = '<span>' + itemParentText + '</span>';
                            item.querySelector('.submenu').insertAdjacentHTML('afterbegin', itemParent);
                            item.querySelector('.submenu').insertAdjacentHTML('afterbegin', backLink);
                            item.querySelector('.nav-back-link').insertAdjacentHTML('afterbegin', itemBackTextExtra);
                            item.querySelector('.category-item.parent > a').addEventListener('click', function (e) {
                                e.preventDefault();
                                item.classList.add('active');
                                $('.menu-deco').animate({scrollTop: 0}, 'normal');
                            });
                            item.querySelector('.nav-back-link').addEventListener('click', function () {
                                return item.classList.remove('active');
                            });
                        });

                        menuFirstItem.insertAdjacentHTML('beforebegin', menuTitle);

                        document.querySelector('.nav-close-link .nav-toggle').addEventListener('click', function () {
                            document.body.classList.remove('nav-is-toggled');
                            navExpand.forEach(function (item) {
                                item.classList.remove('active');
                            });
                        });

                        var ham = document.querySelector('.nav-toggle');
                        ham.addEventListener('click', function () {
                            document.body.classList.toggle('nav-is-toggled');
                        });
                    })();
                }
            });
        }

        function headerBelt() {
            if (document.querySelector('.header-belt-top')) {
                // Add close icon to static block
                document.querySelector('.header-belt-top').insertAdjacentHTML('beforeend',
                    '<a href="#" class="belt-hide deco-icon-close"><span class="visually-hidden">Close</span></a>');

                // Listen if anybody click close
                document.querySelector('.belt-hide').addEventListener('click', function (e) {
                    e.preventDefault();
                    localStorage.setItem('headerBeltLocal', 'yes');
                    sessionStorage.setItem('headerBeltSession', 'yes');
                    document.querySelector('.header-belt-top').style.display = 'none';
                })

                // Show belt if we have new session
                // if (sessionStorage.getItem('headerBeltSession') === null) {
                //   document.querySelector('.header-belt-top').classList.add('show');
                //   document.body.classList.add('header-belt-top-show');
                // }
            }
        }

        function headerBeltExtra() {
            if (document.querySelector('.header-belt-extra-top')) {
                // Add close icon to static block
                document.querySelector('.header-belt-extra-top').insertAdjacentHTML('beforeend',
                    '<a href="#" class="belt-hide belt-extra deco-icon-close"><span class="visually-hidden">Close</span></a>');

                // Listen if anybody click close
                document.querySelector('.belt-hide.belt-extra').addEventListener('click', function (e) {
                    e.preventDefault();
                    localStorage.setItem('headerBeltExtraLocal', 'yes');
                    sessionStorage.setItem('headerBeltExtraSession', 'yes');
                    document.querySelector('.header-belt-extra-top').style.display = 'none';
                })

                // Show belt if we have new session
                if (sessionStorage.getItem('headerBeltExtraSession') === null) {
                    document.querySelector('.header-belt-extra-top').classList.add('show');
                }

                if (typeof deadlineBF8 !== 'undefined') {
                    initializeClock('bf8-counter', deadlineBF8);
                }
                if (typeof deadlineBF100 !== 'undefined') {
                    initializeClock('bf100-counter', deadlineBF100);
                }
                if (typeof deadlineSanta !== 'undefined') {
                    initializeClock('santa-counter', deadlineSanta);
                }
                if (typeof deadlineVacation !== 'undefined') {
                    initializeClock('vacation-counter', deadlineVacation);
                }
                if (typeof deadlineDeco !== 'undefined') {
                    initializeClock('santa-counter', deadlineDeco);
                }
            }
        }

        function openTabCustomLinks() {
            $('.product-reviews-summary .action').on('click', function () {
                $('#tab-label-reviews-title').trigger('click');
            });

            var hashReview = window.location.hash;

            if (hashReview) {
                if (hashReview.includes('#reviews')) {
                    setTimeout(function () {
                        $('#tab-label-reviews-title').trigger('click');
                    }, 1500);
                }
            }

            $('.product-item-delivery .delivery-free a, .product-item-delivery .delivery-time a').on('click', function () {
                $('#tab-label-delivery-title').trigger('click');
            });
        }

        function galleryStore() {
            $('.gallery-slider').animate({}, function () {
                $('.gallery-slider').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    responsive: [
                        {
                            breakpoint: 544,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            });
        }

        // function chatDeco() {
        //   if (document.querySelector('.chat-decofire')) {
        //     var chatButton = document.querySelector('.chat-decofire');
        //
        //     chatButton.addEventListener('click', function (e) {
        //       e.preventDefault();
        //       smartsupp('chat:open');
        //     })
        //
        //     smartsupp('theme:styles', 'custom', {
        //       '.widget-online': {
        //         "display": "none !important"
        //       },
        //       '.widget-offline': {
        //         "display": "none !important"
        //       },
        //     });
        //
        //     smartsupp('on', 'status', function (status) {
        //       if (status == 'online') {
        //         chatButton.classList.add('online');
        //         chatButton.classList.remove('offline');
        //       } else {
        //         chatButton.classList.add('offline');
        //         chatButton.classList.remove('online');
        //       }
        //     });
        //   }
        // }

        function getTimeRemaining(endtime) {
            var t = Date.parse(endtime) - Date.parse(new Date());
            var minutes = Math.floor((t / 1000 / 60) % 60);
            var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
            var days = Math.floor(t / (1000 * 60 * 60 * 24));

            return {
                'total': t,
                'days': days,
                'hours': hours,
                'minutes': minutes
            };
        }

        function initializeClock(id, endtime) {
            var clock = document.getElementById(id);
            if (clock) {
                var daysSpan = clock.querySelector('.days');
                var hoursSpan = clock.querySelector('.hours');
                var minutesSpan = clock.querySelector('.minutes');

                function updateClock() {
                    var t = getTimeRemaining(endtime);

                    daysSpan.innerHTML = ('0' + t.days).slice(-2);
                    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);

                    if (t.total <= 0) {
                        clearInterval(timeinterval);
                    }

                    if (t.total < 0) {
                        daysSpan.innerHTML = '00';
                        hoursSpan.innerHTML = '00';
                        minutesSpan.innerHTML = '00';
                    }
                }

                updateClock();
                var timeinterval = setInterval(updateClock, 60000);
            }
        }

        function faqToogle() {
            $('.faq-list .faq-item').on('click', '.faq-item--title', function (e) {
                $(this).parent('.faq-item').toggleClass('toggle');
            })

            $('.faq-search-box .btn').on('click', function (e) {
                e.preventDefault();

                $(this).toggleClass('toggleAll');

                if ($(this).hasClass('toggleAll')) {
                    $('.faq-list .faq-item').each(function () {
                        $(this).addClass('toggle');
                    })
                } else {
                    $('.faq-list .faq-item').each(function () {
                        $(this).removeClass('toggle');
                    })
                }
            })
        }

        function faqFilterQuestion() {
            $('.faq-category .faq-category-item').on('click', function () {
                var cat = $(this).data('cat');
                var faqItems = $('.faq-list .faq-item');

                if ($(this).hasClass('active')) { //unmark active cat -> show all items
                    $(this).removeClass('active');
                    faqItems.show();
                } else {
                    $('.faq-category .faq-category-item').removeClass('active');
                    $(this).addClass('active');

                    faqItems.filter(':visible').hide();
                    faqItems.filter('[data-cat="' + cat + '"]').show();
                }
            })
        }

        function faqSearch() {
            var typingTimer;
            var doneTypingInterval = 500;
            var stopTyping = false;
            var searchInput = $('.f-search');

            searchInput.keyup(function () {
                clearTimeout(typingTimer);
                if (searchInput.val()) {
                    typingTimer = setTimeout(doneTyping, doneTypingInterval);
                } else {
                    clearTyping();
                }
            });

            function doneTyping() {
                var searchString = searchInput.val();
                stopTyping = true;
                searchInput.attr('disabled');

                $('.faq-list .faq-item').each(function (index, value) {
                    var currentName = $(value).html();
                    var currentNameWithoutDiactricMarks = slugify(currentName);
                    if (currentName.toUpperCase().indexOf(searchString.toUpperCase()) > -1 ||
                        currentNameWithoutDiactricMarks.toUpperCase().indexOf(searchString.toUpperCase()) > -1) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                }).promise().done(function () {
                    searchInput.removeAttr('disabled')
                });
            }

            function slugify(str) {
                var map = {
                    'a': 'á|à|ã|â|À|Á|Ã|Â|ą|Ą',
                    'e': 'é|è|ê|É|È|Ê|ę|Ę',
                    'i': 'í|ì|î|Í|Ì|Î',
                    'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ|ó|Ó',
                    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
                    'c': 'ç|Ç|ć|Ć',
                    'n': 'ñ|Ñ|ń|Ń',
                    'l': 'ł|Ł',
                    's': 'ś|Ś',
                    'z': 'ź|Ź|ż|Ż'
                };

                str = str.toLowerCase();

                for (var pattern in map) {
                    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
                }

                return str;
            }

            function clearTyping() {
                searchInput.attr('disabled');

                $('.faq-list .faq-item').each(function () {
                    $(this).show();
                }).promise().done(function () {
                    searchInput.removeAttr('disabled');
                });
            }
        }

        function showMore() {
            $('.read-more').on('click touch', function () {
                $(this).addClass('show');
            });
        }

        function msieversion() {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
            {
                $('.message.error.ie').show();
            }

            return false;
        }

        function landingProductDetailsSLider() {
            $('.dlp-pd-details-slider').animate({}, function () {
                $('.dlp-pd-details-slider').each(function () {
                    let slider = $(this).not('.slick-initialized').slick({
                        arrows: true,
                        dots: false,
                        autoplay: false,
                        autoplaySpeed: 4000
                    });

                    let list = $(this).parent().prev().children('.dlp-pd-details-list').children('span')

                    list.on('click', function () {
                        let slideIndex = $(this).data('slide-id');
                        list.parent().children('span').removeClass('active')
                        $(this).addClass('active');
                        slider.slick('slickGoTo', slideIndex, false);
                        let sliderDetails = $(this).parent('.dlp-pd-details-list').parent('.dlp-pd-image').next('.dlp-pd-details');

                        if (!window.matchMedia('(min-width: ' + bp.lg + 'px)').matches) {
                            $('html, body').animate({
                                scrollTop: sliderDetails.offset().top - 69
                            }, 'slow');
                        }
                    });

                    slider.on('afterChange', function (event, slick, currentSlide) {
                        list.parent().children('span').removeClass('active');
                        list.parent().children('span[data-slide-id="' + currentSlide + '"]').addClass('active');
                    });
                });
            });
        }

        function modalVideo() {
            if (document.querySelector('.decofire-landing-page')) {
                const modal = document.querySelector('.modal-video');
                const modalContent = document.querySelector('.modal-video-content');
                const main = document.querySelector('main');
                const closeModalBtn = document.querySelector('.close-modal');
                const html = document.getElementsByTagName('html');

                document.querySelectorAll('.open-modal-video').forEach(item => {
                    item.addEventListener('click', function (e) {
                        e.preventDefault();
                        const videoUrl = new URL(item.getAttribute('href'));
                        const videoID = videoUrl.searchParams.get("v");
                        const videoIframe = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

                        modal.style.display = 'flex';
                        modal.removeAttribute('aria-hidden');
                        main.setAttribute('aria-hidden', 'true');
                        html[0].classList.add('modal-show');

                        modalContent.insertAdjacentHTML('afterbegin', videoIframe);
                    })
                })

                closeModalBtn.addEventListener('click', function () {
                    modal.style.display = 'none';
                    modal.setAttribute('aria-hidden', 'true');
                    main.removeAttribute('aria-hidden');
                    modalContent.innerHTML = '';
                    html[0].classList.remove('modal-show');
                });
            }
        }

        function contactMap() {
            if (document.querySelector('.contact-locations')) {
                const map = document.getElementById('mapa');
                let locations = document.querySelectorAll('.contact-locations-item');

                locations.forEach(item => {
                    item.addEventListener('click', function (e) {
                        locations.forEach(items => {
                            items.classList.remove('selected');
                        })
                        item.classList.add('selected');
                        map.src = item.getAttribute('data-location');
                    })
                })
            }
        }

        function modalWalk() {
            if (document.querySelector('.contact-section-team')) {
                const modal = document.querySelector('.modal-video');
                const modalContent = document.querySelector('.modal-video-content');
                const main = document.querySelector('main');
                const closeModalBtn = document.querySelector('.close-modal');
                const html = document.getElementsByTagName('html');

                document.querySelectorAll('.open-modal-video').forEach(item => {
                    item.addEventListener('click', function (e) {
                        e.preventDefault();
                        const videoUrl = new URL(item.getAttribute('href'));
                        const videoIframe = '<iframe src="' + videoUrl + '" width="100%" height="100%" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>';

                        modal.style.display = 'flex';
                        modal.removeAttribute('aria-hidden');
                        main.setAttribute('aria-hidden', 'true');
                        html[0].classList.add('modal-show');

                        modalContent.insertAdjacentHTML('afterbegin', videoIframe);
                    })
                })

                closeModalBtn.addEventListener('click', function () {
                    modal.style.display = 'none';
                    modal.setAttribute('aria-hidden', 'true');
                    main.removeAttribute('aria-hidden');
                    modalContent.innerHTML = '';
                    html[0].classList.remove('modal-show');
                });
            }
        }

        function rankingToogle() {
            $('.deco-ranking-items .deco-ranking-item').on('click', '.dri-product-attribute--title', function (e) {
                $(this).parent('.dri-product-attribute').toggleClass('toggle');
            })
        }

        function adventCalendar() {
            if (document.querySelector('.advent-calendar')) {
                let sound = document.getElementById('ac-sound');
                let deadlineAC = dateNow.toISOString().slice(0, 10);

                document.querySelectorAll('.advent-calendar-item').forEach(function (item) {
                    let itemDate = item.dataset.date;
                    if (deadlineAC > itemDate) {
                        item.classList.add('outdate', 'clicked')
                    }

                    item.addEventListener('click', function (e) {
                        let itemDate = item.dataset.date;

                        if (deadlineAC !== itemDate) {
                            return false;
                        }

                        sound.play();
                        let productUrl = item.dataset.url;

                        if (item.classList.contains('clicked') && e.target === item.lastElementChild) {
                            window.open(productUrl, '_blank');
                        }

                        item.classList.add('clicked');
                    });
                });

                let clipboardAC = new ClipboardJS('.advent-calendar-item.clicked .aci-copy');

                clipboardAC.on('success', function (e) {
                    e.trigger.parentElement.classList.add('success')

                    setTimeout(function () {
                        return e.trigger.parentElement.classList.remove('success');
                    }, 1200);
                })
            }
        }

        function landingColors() {
            if (document.querySelector('.dlp-colors')) {
                const elems = Array.from(document.querySelectorAll('.dlp-color'));
                const elemsR = Array.from(document.querySelectorAll('.dlp-colors-results-products'));

                elems.forEach(function (item) {
                    item.addEventListener('click', function (e) {
                        let sectionID = item.parentElement.dataset.section || item.parentElement.parentElement.parentElement.parentElement.parentElement.dataset.section;
                        let itemIndex = item.dataset.item;
                        let sectionResults = document.querySelector(`.dlp-colors-results[data-section=${CSS.escape(sectionID)}] [data-section=${CSS.escape(itemIndex)}]`);

                        elems.forEach(elem => elem.classList.remove('active'));
                        elemsR.forEach(elem => elem.classList.remove('active'));
                        item.classList.add('active');
                        sectionResults.classList.add('active');
                        sectionResults.querySelector('.products-slider .product-items').slick.refresh()
                    });
                });
            }
        }

        function landingColorsSlider() {
            $('.dlp-colors').animate({}, function () {
                $('.dlp-colors').each(function () {
                    let carousel = $(this);

                    let destroySlider = function destroySlider() {
                        if (carousel.hasClass('slick-initialized')) {
                            carousel.slick('unslick');
                        }
                    };

                    $(window).on('load resize orientationchange', function () {
                        if (!window.matchMedia('(min-width: ' + bp.sm + 'px)').matches) {
                            if (!carousel.hasClass('slick-initialized')) {
                                carousel.slick({
                                    arrows: true,
                                    dots: false,
                                    infinite: false,
                                    mobileFirst: true
                                });
                            }
                        } else {
                            destroySlider();
                        }
                    });
                });
            });
        }

        function landingAccessoriesSlider() {
            $('.accessories-grid-section').animate({}, function () {
                var module = $(this),
                    carousel = module.find('.accessories-slider');

                var destroySlider = function destroySlider() {
                    if (carousel.hasClass('slick-initialized')) {
                        carousel.slick('unslick');
                    }
                };

                $(window).on('load resize orientationchange', function () {
                    if (!window.matchMedia('(min-width: ' + bp.md + 'px)').matches) {
                        if (!carousel.hasClass('slick-initialized')) {
                            carousel.slick({
                                arrows: true,
                                dots: false,
                                infinite: false,
                                mobileFirst: true,
                            });
                        }
                    } else {
                        destroySlider();
                    }
                });
            });
        }

        function lpSwitcher() {
            if (document.querySelector('.dlp-product-switch')) {
                let btn = document.querySelectorAll('.dlp-product-switch .btn');
                let div = document.querySelectorAll('.dlp-ps')

                btn.forEach(item => {
                    item.addEventListener('click', function (e) {
                        btn.forEach(items => {
                            items.classList.remove('selected');
                        })

                        div.forEach(items => {
                            items.classList.remove('show');
                        })

                        item.classList.add('selected');
                        document.querySelector(item.hash).classList.add('show');
                    })
                })
            }
        }

        function lpProductAdvantages() {
            $('.dlp-product-advantages').animate({}, function () {
                $('.dlp-product-advantages').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    speed: 300,
                });
            });
        }

        function lpCategories() {
            $('.dlp-categories').animate({}, function () {
                $('.dlp-categories').slick({
                    arrows: true,
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 544,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            });
        }
    });
